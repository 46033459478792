<template>
    <section class="border-top mt-3">
        <div class="d-flex mx-0">
            <div class="column-folder bg-white px-0">
                <div class="row mx-0 align-items-center pl-2 border-bottom border-right" style="height:55px;">
                    <div class="col-auto f-600 text-black">
                        Carpetas
                    </div>
                    <el-tooltip content="Crear carpeta" placement="bottom" effect="light">
                        <button class="btn-action-black ml-auto mr-2" @click="crearCarpeta">
                            <i class="icon-plus f-20" />
                        </button>
                    </el-tooltip>
                </div>
                <div class="custom-scroll overflow-auto pt-3 border-right" style="height:calc(100vh - 196px)">
                    <template v-if="carpetas.length > 0">
                        <div v-for="(carpeta,index) in carpetas" :key="index" class="row mx-0 row-folder align-items-center mb-4 cr-pointer" @click="idActive = carpeta.id, obtenerData()">
                            <div class="ml-3 bg-green" :class="carpeta.id == idActive ? 'active-pill': ''" />
                            <div class="col f-16 text-black f-500">
                                {{ carpeta.nombre }}
                            </div>
                            <i class="icon-angle-right f-18 mr-4" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="row mx-0 row-folder align-items-center mb-4">
                            <div class="col text-general2 f-14 text-center">
                                <p>Sin Datos</p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="px-0" style="width: calc(100vw - 560px)">
                <detail-folder :carpeta="carpetaSelect" @actualizar="update" />
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalCrearCarpeta" no-aceptar adicional="Guardar" titulo="Crear Carpeta" @adicional="postCarpeta">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center mb-3">
                    <div class="col-8">
                        <p class="f-15 pl-3">Nombre</p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:30" name="Nombre">
                            <el-input v-model="nombre" placeholder="Nombre carpeta" class="w-100" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>

<script>
import Service from '~/services/faqs/faqs';

export default {
    components: {
        detailFolder: () => import('./components/detailFolder.vue')
    },
    data(){
        return {
            carpetas: [],
            carpetaSelect: {},
            idActive: null,
            nombre: null,
        }
    },
    computed:{
        tipo(){
            switch(this.$route.name){
            case "manager.features-flags.clientes":
                return 1;
            case "manager.features-flags.vendedor":
                return 2;
            case "manager.features-flags.delivery":
                return 3;
            case "manager.features-flags.picking_packing":
                return 4;
            default:
                return 0;
            }
        }
    },
    watch: {
        tipo(){
            this.getCarpetas();
        },
    },
    mounted(){
        this.getCarpetas();
    },
    methods: {
        async getCarpetas(){
            try {
                const params = {
                    tipo: this.tipo
                }
                const {data} = await Service.getCarpetas(params);
                this.carpetas = data.carpetas;
                if(data.carpetas.length > 0){
                    this.idActive = data.carpetas[0].id;
                    this.obtenerData();
                    return;
                }
                this.limpiar();
            } catch(error){
                this.error_catch(error)
            }
        },
        async postCarpeta(){
            try {
                if(this.tipo == 0)return this.notificacion('Alerta','No se puede realizar, está acción','warning');
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const model = {
                    tipo: this.tipo,
                    nombre: this.nombre
                };
                const {data} = await Service.postCarpeta(model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.carpetas = data.carpetas;
                this.$refs.modalCrearCarpeta.toggle();
            } catch(error){
                this.error_catch(error)
            }
        },
        obtenerData(){
            this.carpetaSelect = this.carpetas.find((element) => element.id === this.idActive);
        },
        update(data){
            if(data){
                return this.carpetas = data;
            }
            this.getCarpetas();
        },
        limpiar(){
            this.carpetaSelect = {};
            this.idActive = null;
            this.nombre = null;
        },
        crearCarpeta(){
            this.$refs.validacion.reset();
            this.$refs.modalCrearCarpeta.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.column-folder{
    width: 442px;
}
.row-folder{
    .active-pill{
        width: 6px;
        height: 29px;
        border-radius: 12px;
    }
}
</style>